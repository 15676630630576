@charset "UTF-8";
* {
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a {
  user-drag: none;
  /* Забороняє перетягування посилань */
  -webkit-user-drag: none;
  -moz-user-select: none;
       user-select: none;
  /* Забороняє виділення тексту в посиланнях */
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: auto;
  /* Зберігає клікабельність */
}

ul {
  list-style-type: none;
}

@font-face {
  font-family: "Vast Shadow";
  src: url("../../fonts/font-logo/vastshadow-regular-webfont.woff2") format("woff2"), url("../../fonts/font-logo/vastshadow-regular-webfont.woff") format("woff"), url("../../fonts/font-logo/vastshadow-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Liberteen";
  src: url("../../fonts/Liberteen/Liberteen-Regular.woff2") format("woff2"), url("../../fonts/Liberteen/Liberteen-Regular.woff") format("woff"), url("../../fonts/Liberteen/Liberteen-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Merriweather";
  src: url("../../fonts/Merriweather/merriweather-bold-webfont.woff2") format("woff2"), url("../../fonts/Merriweather/merriweather-bold-webfont.woff") format("woff"), url("../../fonts/Merriweather/merriweather-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Koulen";
  src: url("../../fonts/Koulen/koulen-regular-webfont.woff2") format("woff2"), url("../../fonts/Koulen/koulen-regular-webfont.woff") format("woff"), url("../../fonts/Koulen/koulen-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.mastery-distillation__text.digit, .taste__text.digit, .visual__text.digit, .greatness__text.digit, .our-us__text.digit {
  opacity: 0;
  transform: translateX(-40px);
  transition: all 0.6s ease-out;
}

.mastery-distillation__text.digit.visible, .taste__text.digit.visible, .visual__text.digit.visible, .greatness__text.digit.visible, .our-us__text.digit.visible {
  opacity: 1;
  transform: none;
}

.header {
  border-bottom: 1.5px solid rgba(148, 146, 146, 0.055);
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  background: linear-gradient(0deg, rgba(145, 144, 144, 0.2), rgba(159, 159, 160, 0.404));
  box-shadow: 0px 0px 26px 0 rgba(3, 3, 3, 0.2);
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 3;
}
@media (min-width: 1024px) {
  .header {
    height: 100px;
  }
}
.header__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 10px;
}
@media (min-width: 1024px) {
  .header__wrapper {
    flex-direction: column;
    padding: 1rem 2.5rem;
  }
}
.header__upcontainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo {
  display: flex;
  align-items: center;
}
@media (min-width: 1024px) {
  .header__logo {
    flex-direction: row;
  }
}
.header__logo-text {
  display: flex;
  flex-direction: column;
}
.header__logo .vast-shadow {
  color: #0066cc;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  font-family: "Vast Shadow";
}
@media (min-width: 762px) {
  .header__logo .vast-shadow {
    font-size: 26px;
  }
}
.header__logo .merriweather {
  color: #0066cc;
  font-size: 1.2rem;
  line-height: 1.25;
  font-weight: normal;
  font-family: "Merriweather";
  padding: 0 10px;
  display: flex;
  align-items: center;
}
@media (min-width: 762px) {
  .header__logo .merriweather {
    font-size: 1.45rem;
  }
}
.header__logo span.line {
  flex-grow: 1;
  height: 3px;
  background-color: #0066cc;
  margin: 0 5px;
}
.header__icons {
  display: flex;
}
.header__icon {
  display: block;
  color: #0066cc;
  cursor: pointer;
  font-size: 25px;
}
@media (min-width: 1024px) {
  .header__icon {
    display: none;
  }
}
.header__menu {
  height: 0;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  width: 100vw;
  background: #F9F9F9;
  transition: 0.5s;
  overflow: hidden;
}
.header__menu.active {
  height: 335px;
}
@media (min-width: 1024px) {
  .header__menu {
    width: auto;
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    overflow: visible;
    transition: 0;
  }
}
.header__menu-items {
  list-style-type: none;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto 0 auto;
  text-align: center;
  transition: 0.5s;
  opacity: 0;
}
@media (min-width: 1024px) {
  .header__menu-items {
    display: flex;
    margin: 0;
    opacity: 1;
  }
}
.header__menu.active ul {
  opacity: 1;
}
.header__menu-item {
  margin-bottom: 12px;
}
@media (min-width: 1024px) {
  .header__menu-item {
    margin-bottom: 0;
    display: flex;
    margin-right: 1.2rem;
  }
  .header__menu-item:last-child {
    display: flex;
    align-self: center;
    margin-right: 0px;
  }
}
.header__menu-link {
  display: block;
  font-size: 17px;
  color: #0066cc;
  line-height: 1.5;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: 0.2em 0;
}
.header__menu-link:hover {
  color: #003366;
}
.header__menu-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffcc00;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.header__menu-link:hover::before {
  transform: scaleX(1);
}
@media (min-width: 1024px) {
  .header__menu-link {
    font-size: 18px;
    color: #0066cc;
    line-height: 1.5;
  }
}
@media (min-width: 1440px) {
  .header__menu-link {
    font-size: 22px;
    color: #0066cc;
    line-height: 1.5;
  }
}
.header__menu-lang {
  width: 50px;
  height: 30px;
  cursor: pointer;
  border-radius: 10px;
}
@media (min-width: 1024px) {
  .header__menu-lang {
    margin-left: 2rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(2rem);
          backdrop-filter: blur(2rem);
  background: linear-gradient(0deg, rgba(194, 193, 193, 0.2), rgba(159, 159, 160, 0.404));
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.modal-overlay--visible {
  display: flex;
}

.title__red {
  color: red;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.modal__title {
  font-size: 1.4rem;
  margin-bottom: 15px;
}
@media (min-width: 762px) {
  .modal__title {
    font-size: 2rem;
  }
}
.modal__text {
  margin-bottom: 20px;
  font-size: 1rem;
}
@media (min-width: 762px) {
  .modal__text {
    font-size: 1.3rem;
  }
}
.modal__checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
.modal__checkbox {
  width: 20px;
  height: 20px;
}
.modal__label {
  font-size: 0.8rem;
}
@media (min-width: 762px) {
  .modal__label {
    font-size: 1rem;
  }
}
.modal__button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.modal__button--disabled {
  background: #ccc;
  cursor: not-allowed;
}
.modal__button:not(.modal__button--disabled) {
  background: #007bff;
  color: white;
  cursor: pointer;
}

.enabled {
  cursor: pointer;
  border: 2px solid green;
  background-color: white;
  color: green;
}

.modal-open {
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero {
  background: #F9F9F9;
}
.hero__wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
  margin: 4.9rem auto 2rem auto;
  padding-bottom: 4rem;
}
@media (min-width: 762px) {
  .hero__wrapper {
    margin: 6.3rem auto 0 auto;
  }
}
@media (min-width: 1024px) {
  .hero__wrapper {
    flex-direction: row;
    margin: 6.3rem auto 0 auto;
    padding-bottom: 0rem;
  }
}
.hero__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  font-family: "Liberteen";
}
@media (min-width: 1024px) {
  .hero__text {
    width: 100%;
    padding: 0 2rem;
  }
}
.hero__text-title {
  font-size: 2.2rem;
  color: #0066cc;
  margin-bottom: 1.2rem;
  text-align: center;
}
@media (min-width: 762px) {
  .hero__text-title {
    font-size: 2.5rem;
  }
}
@media (min-width: 1024px) {
  .hero__text-title {
    font-size: 3rem;
  }
}
.hero__text-lines {
  display: flex;
}
.hero__text-line {
  width: 8rem;
  height: 3.8px;
  background-color: #0066cc;
  margin-bottom: 2rem;
}
.hero__text-line:last-child {
  background-color: #ffcc00;
}
@media (min-width: 762px) {
  .hero__text-line {
    width: 9rem;
  }
}
.hero__text-item {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #0066cc;
  line-height: 1.5;
  text-align: center;
}
@media (min-width: 1024px) {
  .hero__text-item {
    width: 440px;
  }
}
.hero__text-item .weight {
  font-weight: 600;
}
.hero__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}
@media (min-width: 762px) {
  .hero__img {
    height: 800px;
  }
}
@media (min-width: 1024px) {
  .hero__img {
    width: 65%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    margin-bottom: 0;
  }
}
.hero__img > img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}

.blur-with-logo__wrapper {
  width: 100%;
  height: 250px;
}
@media (min-width: 762px) {
  .blur-with-logo__wrapper {
    height: 325px;
  }
}
@media (min-width: 1024px) {
  .blur-with-logo__wrapper {
    height: 500px;
  }
}
.blur-with-logo__bg {
  position: fixed;
  top: 1rem;
  left: -343px;
  z-index: -1;
  align-items: center;
}
@media (min-width: 762px) {
  .blur-with-logo__bg {
    top: 1rem;
    left: -250px;
  }
}
@media (min-width: 1024px) {
  .blur-with-logo__bg {
    top: -15rem;
    left: -100px;
  }
}
.blur-with-logo__bg-picture {
  width: 120%;
  height: 100%;
}
@media (min-width: 762px) {
  .blur-with-logo__bg-picture {
    width: 120%;
  }
}
@media (min-width: 1024px) {
  .blur-with-logo__bg-picture {
    width: 110%;
  }
}
.blur-with-logo__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blur-with-logo__logo {
  color: #F9F9F9;
  font-size: 3rem;
  font-weight: normal;
  font-family: "Koulen";
  flex-direction: column;
  text-align: center;
}
@media (min-width: 762px) {
  .blur-with-logo__logo {
    font-size: 4rem;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .blur-with-logo__logo {
    font-size: 6.5rem;
  }
}
.blur-with-logo__list {
  color: #F9F9F9;
  font-size: 3rem;
  font-weight: normal;
  font-family: "Koulen";
  flex-direction: column;
  text-align: center;
}
@media (min-width: 762px) {
  .blur-with-logo__list {
    font-size: 4rem;
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .blur-with-logo__list {
    font-size: 6.5rem;
  }
}
@media (min-width: 762px) {
  .blur-with-logo__list-item {
    margin-right: 2rem;
  }
}
.blur-with-logo__list-item:nth-child(2) {
  margin-right: 0rem;
}

.our-us {
  background: #F9F9F9;
  font-family: "Liberteen";
}
.our-us__wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .our-us__wrapper {
    flex-direction: row-reverse;
    padding-bottom: 0rem;
  }
}
.our-us__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 10px;
}
@media (min-width: 1024px) {
  .our-us__container {
    padding: 2rem 0;
    width: 100%;
  }
}
.our-us__title {
  color: #0066cc;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #0066cc;
}
@media (min-width: 762px) {
  .our-us__title {
    font-size: 26px;
  }
}
.our-us__text {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #0066cc;
  line-height: 1.5;
  text-align: center;
}
.our-us__text:last-child {
  margin-bottom: 0px;
}
.our-us__text .weight {
  font-weight: 600;
}
@media (min-width: 1024px) {
  .our-us__text {
    width: 440px;
  }
}
.our-us__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}
@media (min-width: 762px) {
  .our-us__img {
    height: 800px;
  }
}
@media (min-width: 1024px) {
  .our-us__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}
.our-us__img > img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}

.greatness {
  background: #F9F9F9;
  font-family: "Liberteen";
}
.greatness__wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .greatness__wrapper {
    flex-direction: row;
    padding-bottom: 0rem;
  }
}
.greatness__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 10px;
}
@media (min-width: 1024px) {
  .greatness__container {
    padding: 2rem 0;
    width: 100%;
  }
}
.greatness__title {
  color: #0066cc;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #0066cc;
}
@media (min-width: 762px) {
  .greatness__title {
    font-size: 26px;
  }
}
.greatness__text {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #0066cc;
  line-height: 1.5;
  text-align: center;
}
.greatness__text:last-child {
  margin-bottom: 0px;
}
.greatness__text .weight {
  font-weight: 600;
}
@media (min-width: 1024px) {
  .greatness__text {
    width: 440px;
  }
}
.greatness__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}
@media (min-width: 762px) {
  .greatness__img {
    height: 800px;
  }
}
@media (min-width: 1024px) {
  .greatness__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}
.greatness__img > img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}

.visual {
  background: #F9F9F9;
  font-family: "Liberteen";
}
.visual__wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
  padding-bottom: 4rem;
}
@media (min-width: 1024px) {
  .visual__wrapper {
    flex-direction: row-reverse;
    padding-bottom: 0rem;
  }
}
.visual__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 10px;
}
@media (min-width: 1024px) {
  .visual__container {
    width: 100%;
    padding: 2rem 0;
  }
}
.visual__title {
  color: #0066cc;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #0066cc;
}
@media (min-width: 762px) {
  .visual__title {
    font-size: 26px;
  }
}
.visual__text {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #0066cc;
  line-height: 1.5;
  text-align: center;
}
.visual__text:last-child {
  margin-bottom: 0px;
}
.visual__text .weight {
  font-weight: 600;
}
@media (min-width: 1024px) {
  .visual__text {
    width: 440px;
  }
}
.visual__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}
@media (min-width: 762px) {
  .visual__img {
    height: 800px;
  }
}
@media (min-width: 1024px) {
  .visual__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}
.visual__img > img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}

.taste {
  background: #F9F9F9;
  font-family: "Liberteen";
}
.taste__wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
}
@media (min-width: 1024px) {
  .taste__wrapper {
    flex-direction: row;
    padding-bottom: 0rem;
  }
}
.taste__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 10px;
}
@media (min-width: 1024px) {
  .taste__container {
    width: 100%;
    padding: 2rem 0;
  }
}
.taste__title {
  color: #0066cc;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #0066cc;
}
@media (min-width: 762px) {
  .taste__title {
    font-size: 26px;
  }
}
.taste__text {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #0066cc;
  line-height: 1.5;
  text-align: center;
}
.taste__text:last-child {
  margin-bottom: 0px;
}
.taste__text .weight {
  font-weight: 600;
}
@media (min-width: 1024px) {
  .taste__text {
    width: 440px;
  }
}
.taste__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}
@media (min-width: 762px) {
  .taste__img {
    height: 800px;
  }
}
@media (min-width: 1024px) {
  .taste__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}
.taste__img > img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
}

.mastery-distillation {
  font-family: "Liberteen";
}
.mastery-distillation__wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: stretch;
  border-radius: 0 0 3rem 3rem;
}
@media (min-width: 1024px) {
  .mastery-distillation__wrapper {
    flex-direction: row;
    border-radius: 0 0 3rem 3rem;
    background: #eeeeee;
  }
}
.mastery-distillation__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem;
  border-radius: 0 0 0 3rem;
}
@media (min-width: 1024px) {
  .mastery-distillation__container {
    width: 100%;
    padding: 2rem 2rem;
  }
}
.mastery-distillation__title {
  color: #0066cc;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  margin-bottom: 2rem;
  text-align: center;
}
@media (min-width: 762px) {
  .mastery-distillation__title {
    font-size: 26px;
  }
}
.mastery-distillation__text {
  display: flex;
  margin-bottom: 1rem;
  font-size: clamp(1.3rem, 2vw, 2rem);
  color: #0066cc;
  line-height: 1.5;
  text-align: center;
}
@media (min-width: 1024px) {
  .mastery-distillation__text {
    width: 600px;
  }
}
.mastery-distillation__img {
  background: #eeeeee;
  width: auto;
  height: 260px;
  margin-bottom: 2rem;
}
@media (min-width: 762px) {
  .mastery-distillation__img {
    height: 450px;
  }
}
@media (min-width: 1024px) {
  .mastery-distillation__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}
.mastery-distillation__img > img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 1024px) {
  .mastery-distillation__img > img {
    border-radius: 0 0 3rem 0;
  }
}

.collection-produc {
  background-color: #F9F9F9;
  padding: 2rem 1rem 5rem 1rem;
}
.collection-produc__wrapper {
  display: flex;
  flex-flow: column;
  position: relative;
}
.collection-produc__title {
  color: #0066cc;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  margin-bottom: 3rem;
  padding: 0 1rem 1rem 1rem;
  border-bottom: 1px solid #0066cc;
  align-self: center;
  text-align: center;
}
@media (min-width: 762px) {
  .collection-produc__title {
    font-size: 26px;
  }
}
@media (min-width: 762px) {
  .collection-produc__title {
    margin-bottom: 5rem;
  }
}
@media (min-width: 1024px) {
  .collection-produc__title {
    font-size: 35px;
  }
}
.collection-produc__container {
  cursor: grab;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 10px;
  padding: 10px;
}
.collection-produc__container::-webkit-scrollbar {
  width: 16px;
}
.collection-produc__container::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}
.collection-produc__container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #353435;
}
.collection-produc__container:active {
  cursor: grabbing;
}
.collection-produc__item-link {
  text-decoration: none;
  margin-bottom: 2rem;
}
@media (min-width: 762px) {
  .collection-produc__item-link {
    flex: 0 0 auto;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
}
.collection-produc__item-img {
  width: 13rem;
  height: 20rem;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 762px) {
  .collection-produc__item-img {
    width: 20rem;
    height: 30rem;
  }
}

.footer {
  border-top: 3px solid #F9F9F9;
  background: #353435;
  height: 45rem;
  padding: 2rem 1rem;
}
@media (min-width: 762px) {
  .footer {
    padding: 2rem 7rem;
    height: 60rem;
  }
}
@media (min-width: 1024px) {
  .footer {
    padding: 5rem 3rem 5rem 3rem;
    height: 45rem;
  }
}
.footer__wrapper {
  display: flex;
  flex-direction: column;
}
.footer__title {
  color: #F9F9F9;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding: 0 1rem 1rem 1rem;
  border-bottom: 1px solid #F9F9F9;
}
@media (min-width: 762px) {
  .footer__title {
    font-size: 26px;
  }
}
@media (min-width: 1024px) {
  .footer__title {
    font-size: 35px;
    margin-bottom: 3rem;
  }
}
.footer__container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
@media (min-width: 1024px) {
  .footer__container {
    flex-direction: row;
  }
}
.footer__contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 5rem;
  width: 100%;
}
@media (min-width: 1024px) {
  .footer__contact-form {
    flex-basis: 50rem;
    margin-bottom: 0;
    margin-right: 4.6rem;
  }
}
.footer__forms {
  display: flex;
  justify-content: center;
  width: 70%;
}
@media (min-width: 1024px) {
  .footer__forms {
    padding-left: 5rem;
  }
}
.footer__qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  margin-bottom: 5rem;
}
@media (min-width: 762px) {
  .footer__qr {
    width: 25rem;
  }
}
@media (min-width: 1024px) {
  .footer__qr {
    margin-bottom: 0;
  }
}
.footer__qr-title {
  padding-top: 1rem;
  margin-bottom: 1rem;
  color: #F9F9F9;
  font-size: 1.2rem;
  line-height: 1.25;
  font-weight: normal;
  font-size: 20px;
}
@media (min-width: 762px) {
  .footer__qr-title {
    font-size: 1.45rem;
  }
}
@media (min-width: 762px) {
  .footer__qr-title {
    padding-top: 0;
    margin-bottom: 2rem;
    font-size: 30px;
  }
}
.footer__qr-img {
  width: 100%;
  height: 100%;
}
.footer__contacts {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.footer__brand {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 220px;
  height: auto;
}
@media (min-width: 762px) {
  .footer__brand {
    width: 260px;
  }
}
@media (min-width: 1024px) {
  .footer__brand {
    width: 400px;
  }
}
.footer__brand .vast-shadow {
  color: #F9F9F9;
  font-size: 2rem;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  font-family: "Vast Shadow";
  font-size: 2.3rem;
}
@media (min-width: 762px) {
  .footer__brand .vast-shadow {
    font-size: 26px;
  }
}
@media (min-width: 762px) {
  .footer__brand .vast-shadow {
    font-size: 2.7rem;
  }
}
@media (min-width: 1024px) {
  .footer__brand .vast-shadow {
    font-size: 4.3rem;
  }
}
.footer__brand .merriweather {
  color: #F9F9F9;
  font-size: 1.2rem;
  line-height: 1.25;
  font-weight: normal;
  font-family: "Merriweather";
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 2rem;
}
@media (min-width: 762px) {
  .footer__brand .merriweather {
    font-size: 1.45rem;
  }
}
@media (min-width: 762px) {
  .footer__brand .merriweather {
    font-size: 2.7rem;
  }
}
@media (min-width: 1024px) {
  .footer__brand .merriweather {
    font-size: 3.3rem;
  }
}
.footer__brand span.line {
  flex-grow: 1;
  height: 3px;
  background-color: #F9F9F9;
  margin: 0 5px;
}
.footer__item {
  margin-bottom: 1rem;
}
.footer__item:first-child {
  display: flex;
  align-items: center;
}
.footer__item:last-child {
  margin-bottom: 0;
}
.footer .icon-phone {
  margin-left: 10px;
}
.footer__phone {
  text-decoration: none;
  font-size: 2.3rem;
  color: #F9F9F9;
  font-size: 1.3rem;
}
@media (min-width: 1024px) {
  .footer__phone {
    font-size: 16px;
  }
}
.footer__phone:hover {
  filter: brightness(85%);
}
@media (min-width: 762px) {
  .footer__phone {
    font-size: 2.2rem;
  }
}
@media (min-width: 1024px) {
  .footer__phone {
    font-size: 2.2rem;
  }
}
.footer__phone::before {
  content: url(../../image/phone-solid.svg);
  display: inline-block;
  margin-right: 10px;
  width: 20px;
}
@media (min-width: 1024px) {
  .footer__phone::before {
    width: 25px;
  }
}
.footer__mail {
  text-decoration: none;
  font-size: 2.3rem;
  color: #F9F9F9;
  font-size: 1.3rem;
}
@media (min-width: 1024px) {
  .footer__mail {
    font-size: 16px;
  }
}
.footer__mail:hover {
  filter: brightness(85%);
}
@media (min-width: 762px) {
  .footer__mail {
    font-size: 2.2rem;
  }
}
@media (min-width: 1024px) {
  .footer__mail {
    font-size: 2.2rem;
  }
}
.footer__mail::before {
  content: url(../../image/envelope-solid.svg);
  display: inline-block;
  margin-right: 10px;
  width: 20px;
}
@media (min-width: 1024px) {
  .footer__mail::before {
    width: 25px;
  }
}
.footer__web {
  text-decoration: none;
  font-size: 2.3rem;
  color: #F9F9F9;
  font-size: 1.3rem;
}
@media (min-width: 1024px) {
  .footer__web {
    font-size: 16px;
  }
}
.footer__web:hover {
  filter: brightness(85%);
}
@media (min-width: 762px) {
  .footer__web {
    font-size: 2.2rem;
  }
}
@media (min-width: 1024px) {
  .footer__web {
    font-size: 2.2rem;
  }
}
.footer__web::before {
  content: url(../../image/globe-solid.svg);
  display: inline-block;
  margin-right: 10px;
  width: 20px;
}
@media (min-width: 1024px) {
  .footer__web::before {
    width: 25px;
  }
}
.footer label.error {
  display: none !important;
}
.footer form input.error {
  border-color: red;
}