* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  user-select: none;
}

a {
  user-drag: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
}

ul {
  list-style-type: none;
}

@font-face {
  font-family: Vast Shadow;
  src: url("vastshadow-regular-webfont.4842435c.woff2") format("woff2"), url("vastshadow-regular-webfont.621392e2.woff") format("woff"), url("vastshadow-regular-webfont.8517f12d.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Liberteen;
  src: url("Liberteen-Regular.d21b9c5c.woff2") format("woff2"), url("Liberteen-Regular.55a4a12c.woff") format("woff"), url("Liberteen-Regular.92246f9a.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Merriweather;
  src: url("merriweather-bold-webfont.d57a7df4.woff2") format("woff2"), url("merriweather-bold-webfont.70aedfb6.woff") format("woff"), url("merriweather-bold-webfont.5014b6ed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Koulen;
  src: url("koulen-regular-webfont.26771e3c.woff2") format("woff2"), url("koulen-regular-webfont.86b5c306.woff") format("woff"), url("koulen-regular-webfont.0d927055.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.mastery-distillation__text.digit, .taste__text.digit, .visual__text.digit, .greatness__text.digit, .our-us__text.digit {
  opacity: 0;
  transition: all .6s ease-out;
  transform: translateX(-40px);
}

.mastery-distillation__text.digit.visible, .taste__text.digit.visible, .visual__text.digit.visible, .greatness__text.digit.visible, .our-us__text.digit.visible {
  opacity: 1;
  transform: none;
}

.header {
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
  z-index: 3;
  background: linear-gradient(0deg, #91909033, #9f9fa067);
  border-bottom: 1.5px solid #9492920e;
  width: 100%;
  height: 80px;
  position: fixed;
  box-shadow: 0 0 26px #03030333;
}

@media (width >= 1024px) {
  .header {
    height: 100px;
  }
}

.header__wrapper {
  justify-content: space-between;
  width: 100%;
  padding: 1rem 10px;
  display: flex;
}

@media (width >= 1024px) {
  .header__wrapper {
    flex-direction: column;
    padding: 1rem 2.5rem;
  }
}

.header__upcontainer {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.header__logo {
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .header__logo {
    flex-direction: row;
  }
}

.header__logo-text {
  flex-direction: column;
  display: flex;
}

.header__logo .vast-shadow {
  color: #06c;
  text-align: center;
  font-family: Vast Shadow;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .header__logo .vast-shadow {
    font-size: 26px;
  }
}

.header__logo .merriweather {
  color: #06c;
  align-items: center;
  padding: 0 10px;
  font-family: Merriweather;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.25;
  display: flex;
}

@media (width >= 762px) {
  .header__logo .merriweather {
    font-size: 1.45rem;
  }
}

.header__logo span.line {
  background-color: #06c;
  flex-grow: 1;
  height: 3px;
  margin: 0 5px;
}

.header__icons {
  display: flex;
}

.header__icon {
  color: #06c;
  cursor: pointer;
  font-size: 25px;
  display: block;
}

@media (width >= 1024px) {
  .header__icon {
    display: none;
  }
}

.header__menu {
  background: #f9f9f9;
  width: 100vw;
  height: 0;
  transition: all .5s;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.header__menu.active {
  height: 335px;
}

@media (width >= 1024px) {
  .header__menu {
    transition: 0;
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: auto;
    display: flex;
    position: static;
    overflow: visible;
  }
}

.header__menu-items {
  text-align: center;
  opacity: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto 0;
  list-style-type: none;
  transition: all .5s;
  display: block;
}

@media (width >= 1024px) {
  .header__menu-items {
    opacity: 1;
    margin: 0;
    display: flex;
  }
}

.header__menu.active ul {
  opacity: 1;
}

.header__menu-item {
  margin-bottom: 12px;
}

@media (width >= 1024px) {
  .header__menu-item {
    margin-bottom: 0;
    margin-right: 1.2rem;
    display: flex;
  }

  .header__menu-item:last-child {
    align-self: center;
    margin-right: 0;
    display: flex;
  }
}

.header__menu-link {
  color: #06c;
  cursor: pointer;
  padding: .2em 0;
  font-size: 17px;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: relative;
}

.header__menu-link:hover {
  color: #036;
}

.header__menu-link:before {
  content: "";
  background-color: #fc0;
  width: 100%;
  height: 2px;
  transition: transform .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.header__menu-link:hover:before {
  transform: scaleX(1);
}

@media (width >= 1024px) {
  .header__menu-link {
    color: #06c;
    font-size: 18px;
    line-height: 1.5;
  }
}

@media (width >= 1440px) {
  .header__menu-link {
    color: #06c;
    font-size: 22px;
    line-height: 1.5;
  }
}

.header__menu-lang {
  cursor: pointer;
  border-radius: 10px;
  width: 50px;
  height: 30px;
}

@media (width >= 1024px) {
  .header__menu-lang {
    margin-left: 2rem;
  }
}

.modal-overlay {
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
  z-index: 10;
  background: linear-gradient(0deg, #c2c1c133, #9f9fa067);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-overlay--visible {
  display: flex;
}

.title__red {
  color: red;
}

.modal {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px #0003;
}

.modal__title {
  margin-bottom: 15px;
  font-size: 1.4rem;
}

@media (width >= 762px) {
  .modal__title {
    font-size: 2rem;
  }
}

.modal__text {
  margin-bottom: 20px;
  font-size: 1rem;
}

@media (width >= 762px) {
  .modal__text {
    font-size: 1.3rem;
  }
}

.modal__checkbox-container {
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
}

.modal__checkbox {
  width: 20px;
  height: 20px;
}

.modal__label {
  font-size: .8rem;
}

@media (width >= 762px) {
  .modal__label {
    font-size: 1rem;
  }
}

.modal__button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
}

.modal__button--disabled {
  cursor: not-allowed;
  background: #ccc;
}

.modal__button:not(.modal__button--disabled) {
  color: #fff;
  cursor: pointer;
  background: #007bff;
}

.enabled {
  cursor: pointer;
  color: green;
  background-color: #fff;
  border: 2px solid green;
}

.modal-open {
  overflow: hidden;
}

.main {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.hero {
  background: #f9f9f9;
}

.hero__wrapper {
  flex-direction: column-reverse;
  justify-content: stretch;
  margin: 4.9rem auto 2rem;
  padding-bottom: 4rem;
  display: flex;
}

@media (width >= 762px) {
  .hero__wrapper {
    margin: 6.3rem auto 0;
  }
}

@media (width >= 1024px) {
  .hero__wrapper {
    flex-direction: row;
    margin: 6.3rem auto 0;
    padding-bottom: 0;
  }
}

.hero__text {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  font-family: Liberteen;
  display: flex;
}

@media (width >= 1024px) {
  .hero__text {
    width: 100%;
    padding: 0 2rem;
  }
}

.hero__text-title {
  color: #06c;
  text-align: center;
  margin-bottom: 1.2rem;
  font-size: 2.2rem;
}

@media (width >= 762px) {
  .hero__text-title {
    font-size: 2.5rem;
  }
}

@media (width >= 1024px) {
  .hero__text-title {
    font-size: 3rem;
  }
}

.hero__text-lines {
  display: flex;
}

.hero__text-line {
  background-color: #06c;
  width: 8rem;
  height: 3.8px;
  margin-bottom: 2rem;
}

.hero__text-line:last-child {
  background-color: #fc0;
}

@media (width >= 762px) {
  .hero__text-line {
    width: 9rem;
  }
}

.hero__text-item {
  color: #06c;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
}

@media (width >= 1024px) {
  .hero__text-item {
    width: 440px;
  }
}

.hero__text-item .weight {
  font-weight: 600;
}

.hero__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}

@media (width >= 762px) {
  .hero__img {
    height: 800px;
  }
}

@media (width >= 1024px) {
  .hero__img {
    -o-object-fit: contain;
    object-fit: contain;
    width: 65%;
    height: 100%;
    margin-bottom: 0;
  }
}

.hero__img > img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.blur-with-logo__wrapper {
  width: 100%;
  height: 250px;
}

@media (width >= 762px) {
  .blur-with-logo__wrapper {
    height: 325px;
  }
}

@media (width >= 1024px) {
  .blur-with-logo__wrapper {
    height: 500px;
  }
}

.blur-with-logo__bg {
  z-index: -1;
  align-items: center;
  position: fixed;
  top: 1rem;
  left: -343px;
}

@media (width >= 762px) {
  .blur-with-logo__bg {
    top: 1rem;
    left: -250px;
  }
}

@media (width >= 1024px) {
  .blur-with-logo__bg {
    top: -15rem;
    left: -100px;
  }
}

.blur-with-logo__bg-picture {
  width: 120%;
  height: 100%;
}

@media (width >= 762px) {
  .blur-with-logo__bg-picture {
    width: 120%;
  }
}

@media (width >= 1024px) {
  .blur-with-logo__bg-picture {
    width: 110%;
  }
}

.blur-with-logo__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.blur-with-logo__logo {
  color: #f9f9f9;
  text-align: center;
  flex-direction: column;
  font-family: Koulen;
  font-size: 3rem;
  font-weight: normal;
}

@media (width >= 762px) {
  .blur-with-logo__logo {
    flex-direction: row;
    font-size: 4rem;
  }
}

@media (width >= 1024px) {
  .blur-with-logo__logo {
    font-size: 6.5rem;
  }
}

.blur-with-logo__list {
  color: #f9f9f9;
  text-align: center;
  flex-direction: column;
  font-family: Koulen;
  font-size: 3rem;
  font-weight: normal;
}

@media (width >= 762px) {
  .blur-with-logo__list {
    flex-direction: row;
    font-size: 4rem;
  }
}

@media (width >= 1024px) {
  .blur-with-logo__list {
    font-size: 6.5rem;
  }
}

@media (width >= 762px) {
  .blur-with-logo__list-item {
    margin-right: 2rem;
  }
}

.blur-with-logo__list-item:nth-child(2) {
  margin-right: 0;
}

.our-us {
  background: #f9f9f9;
  font-family: Liberteen;
}

.our-us__wrapper {
  flex-direction: column-reverse;
  justify-content: stretch;
  padding-bottom: 4rem;
  display: flex;
}

@media (width >= 1024px) {
  .our-us__wrapper {
    flex-direction: row-reverse;
    padding-bottom: 0;
  }
}

.our-us__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 10px;
  display: flex;
}

@media (width >= 1024px) {
  .our-us__container {
    width: 100%;
    padding: 2rem 0;
  }
}

.our-us__title {
  color: #06c;
  text-align: center;
  border-bottom: 1px solid #06c;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .our-us__title {
    font-size: 26px;
  }
}

.our-us__text {
  color: #06c;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
}

.our-us__text:last-child {
  margin-bottom: 0;
}

.our-us__text .weight {
  font-weight: 600;
}

@media (width >= 1024px) {
  .our-us__text {
    width: 440px;
  }
}

.our-us__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}

@media (width >= 762px) {
  .our-us__img {
    height: 800px;
  }
}

@media (width >= 1024px) {
  .our-us__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}

.our-us__img > img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.greatness {
  background: #f9f9f9;
  font-family: Liberteen;
}

.greatness__wrapper {
  flex-direction: column-reverse;
  justify-content: stretch;
  padding-bottom: 4rem;
  display: flex;
}

@media (width >= 1024px) {
  .greatness__wrapper {
    flex-direction: row;
    padding-bottom: 0;
  }
}

.greatness__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 10px;
  display: flex;
}

@media (width >= 1024px) {
  .greatness__container {
    width: 100%;
    padding: 2rem 0;
  }
}

.greatness__title {
  color: #06c;
  text-align: center;
  border-bottom: 1px solid #06c;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .greatness__title {
    font-size: 26px;
  }
}

.greatness__text {
  color: #06c;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
}

.greatness__text:last-child {
  margin-bottom: 0;
}

.greatness__text .weight {
  font-weight: 600;
}

@media (width >= 1024px) {
  .greatness__text {
    width: 440px;
  }
}

.greatness__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}

@media (width >= 762px) {
  .greatness__img {
    height: 800px;
  }
}

@media (width >= 1024px) {
  .greatness__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}

.greatness__img > img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.visual {
  background: #f9f9f9;
  font-family: Liberteen;
}

.visual__wrapper {
  flex-direction: column-reverse;
  justify-content: stretch;
  padding-bottom: 4rem;
  display: flex;
}

@media (width >= 1024px) {
  .visual__wrapper {
    flex-direction: row-reverse;
    padding-bottom: 0;
  }
}

.visual__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 10px;
  display: flex;
}

@media (width >= 1024px) {
  .visual__container {
    width: 100%;
    padding: 2rem 0;
  }
}

.visual__title {
  color: #06c;
  text-align: center;
  border-bottom: 1px solid #06c;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .visual__title {
    font-size: 26px;
  }
}

.visual__text {
  color: #06c;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
}

.visual__text:last-child {
  margin-bottom: 0;
}

.visual__text .weight {
  font-weight: 600;
}

@media (width >= 1024px) {
  .visual__text {
    width: 440px;
  }
}

.visual__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}

@media (width >= 762px) {
  .visual__img {
    height: 800px;
  }
}

@media (width >= 1024px) {
  .visual__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}

.visual__img > img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.taste {
  background: #f9f9f9;
  font-family: Liberteen;
}

.taste__wrapper {
  flex-direction: column-reverse;
  justify-content: stretch;
  display: flex;
}

@media (width >= 1024px) {
  .taste__wrapper {
    flex-direction: row;
    padding-bottom: 0;
  }
}

.taste__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 10px;
  display: flex;
}

@media (width >= 1024px) {
  .taste__container {
    width: 100%;
    padding: 2rem 0;
  }
}

.taste__title {
  color: #06c;
  text-align: center;
  border-bottom: 1px solid #06c;
  margin-bottom: 1.8rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .taste__title {
    font-size: 26px;
  }
}

.taste__text {
  color: #06c;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
}

.taste__text:last-child {
  margin-bottom: 0;
}

.taste__text .weight {
  font-weight: 600;
}

@media (width >= 1024px) {
  .taste__text {
    width: 440px;
  }
}

.taste__img {
  width: auto;
  height: 650px;
  margin-bottom: 2rem;
}

@media (width >= 762px) {
  .taste__img {
    height: 800px;
  }
}

@media (width >= 1024px) {
  .taste__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}

.taste__img > img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.mastery-distillation {
  font-family: Liberteen;
}

.mastery-distillation__wrapper {
  border-radius: 0 0 3rem 3rem;
  flex-direction: column-reverse;
  justify-content: stretch;
  display: flex;
}

@media (width >= 1024px) {
  .mastery-distillation__wrapper {
    background: #eee;
    border-radius: 0 0 3rem 3rem;
    flex-direction: row;
  }
}

.mastery-distillation__container {
  border-radius: 0 0 0 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

@media (width >= 1024px) {
  .mastery-distillation__container {
    width: 100%;
    padding: 2rem;
  }
}

.mastery-distillation__title {
  color: #06c;
  text-align: center;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .mastery-distillation__title {
    font-size: 26px;
  }
}

.mastery-distillation__text {
  color: #06c;
  text-align: center;
  margin-bottom: 1rem;
  font-size: clamp(1.3rem, 2vw, 2rem);
  line-height: 1.5;
  display: flex;
}

@media (width >= 1024px) {
  .mastery-distillation__text {
    width: 600px;
  }
}

.mastery-distillation__img {
  background: #eee;
  width: auto;
  height: 260px;
  margin-bottom: 2rem;
}

@media (width >= 762px) {
  .mastery-distillation__img {
    height: 450px;
  }
}

@media (width >= 1024px) {
  .mastery-distillation__img {
    width: 68%;
    height: 100%;
    margin-bottom: 0;
  }
}

.mastery-distillation__img > img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media (width >= 1024px) {
  .mastery-distillation__img > img {
    border-radius: 0 0 3rem;
  }
}

.collection-produc {
  background-color: #f9f9f9;
  padding: 2rem 1rem 5rem;
}

.collection-produc__wrapper {
  flex-flow: column;
  display: flex;
  position: relative;
}

.collection-produc__title {
  color: #06c;
  text-align: center;
  text-align: center;
  border-bottom: 1px solid #06c;
  align-self: center;
  margin-bottom: 3rem;
  padding: 0 1rem 1rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .collection-produc__title {
    margin-bottom: 5rem;
    font-size: 26px;
  }
}

@media (width >= 1024px) {
  .collection-produc__title {
    font-size: 35px;
  }
}

.collection-produc__container {
  cursor: grab;
  scroll-behavior: smooth;
  gap: 10px;
  padding: 10px;
  display: flex;
  overflow-x: auto;
}

.collection-produc__container::-webkit-scrollbar {
  width: 16px;
}

.collection-produc__container::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  border-radius: 8px;
}

.collection-produc__container::-webkit-scrollbar-thumb {
  background-color: #353435;
  background-clip: content-box;
  border: 3px solid #0000;
  border-radius: 8px;
}

.collection-produc__container:active {
  cursor: grabbing;
}

.collection-produc__item-link {
  margin-bottom: 2rem;
  text-decoration: none;
}

@media (width >= 762px) {
  .collection-produc__item-link {
    flex: none;
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
}

.collection-produc__item-img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 13rem;
  height: 20rem;
}

@media (width >= 762px) {
  .collection-produc__item-img {
    width: 20rem;
    height: 30rem;
  }
}

.footer {
  background: #353435;
  border-top: 3px solid #f9f9f9;
  height: 45rem;
  padding: 2rem 1rem;
}

@media (width >= 762px) {
  .footer {
    height: 60rem;
    padding: 2rem 7rem;
  }
}

@media (width >= 1024px) {
  .footer {
    height: 45rem;
    padding: 5rem 3rem;
  }
}

.footer__wrapper {
  flex-direction: column;
  display: flex;
}

.footer__title {
  color: #f9f9f9;
  text-align: center;
  border-bottom: 1px solid #f9f9f9;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem 1rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .footer__title {
    font-size: 26px;
  }
}

@media (width >= 1024px) {
  .footer__title {
    margin-bottom: 3rem;
    font-size: 35px;
  }
}

.footer__container {
  flex-direction: column-reverse;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .footer__container {
    flex-direction: row;
  }
}

.footer__contact-form {
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 5rem;
  display: flex;
}

@media (width >= 1024px) {
  .footer__contact-form {
    flex-basis: 50rem;
    margin-bottom: 0;
    margin-right: 4.6rem;
  }
}

.footer__forms {
  justify-content: center;
  width: 70%;
  display: flex;
}

@media (width >= 1024px) {
  .footer__forms {
    padding-left: 5rem;
  }
}

.footer__qr {
  flex-direction: column;
  align-items: center;
  width: 15rem;
  margin-bottom: 5rem;
  display: flex;
}

@media (width >= 762px) {
  .footer__qr {
    width: 25rem;
  }
}

@media (width >= 1024px) {
  .footer__qr {
    margin-bottom: 0;
  }
}

.footer__qr-title {
  color: #f9f9f9;
  margin-bottom: 1rem;
  padding-top: 1rem;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.25;
}

@media (width >= 762px) {
  .footer__qr-title {
    margin-bottom: 2rem;
    padding-top: 0;
    font-size: 30px;
  }
}

.footer__qr-img {
  width: 100%;
  height: 100%;
}

.footer__contacts {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.footer__brand {
  flex-direction: column;
  width: 220px;
  height: auto;
  margin-bottom: 2rem;
  display: flex;
}

@media (width >= 762px) {
  .footer__brand {
    width: 260px;
  }
}

@media (width >= 1024px) {
  .footer__brand {
    width: 400px;
  }
}

.footer__brand .vast-shadow {
  color: #f9f9f9;
  text-align: center;
  font-family: Vast Shadow;
  font-size: 2.3rem;
  font-weight: normal;
  line-height: 1;
}

@media (width >= 762px) {
  .footer__brand .vast-shadow {
    font-size: 2.7rem;
  }
}

@media (width >= 1024px) {
  .footer__brand .vast-shadow {
    font-size: 4.3rem;
  }
}

.footer__brand .merriweather {
  color: #f9f9f9;
  align-items: center;
  padding: 0 10px;
  font-family: Merriweather;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.25;
  display: flex;
}

@media (width >= 762px) {
  .footer__brand .merriweather {
    font-size: 2.7rem;
  }
}

@media (width >= 1024px) {
  .footer__brand .merriweather {
    font-size: 3.3rem;
  }
}

.footer__brand span.line {
  background-color: #f9f9f9;
  flex-grow: 1;
  height: 3px;
  margin: 0 5px;
}

.footer__item {
  margin-bottom: 1rem;
}

.footer__item:first-child {
  align-items: center;
  display: flex;
}

.footer__item:last-child {
  margin-bottom: 0;
}

.footer .icon-phone {
  margin-left: 10px;
}

.footer__phone {
  color: #f9f9f9;
  font-size: 1.3rem;
  text-decoration: none;
}

@media (width >= 1024px) {
  .footer__phone {
    font-size: 16px;
  }
}

.footer__phone:hover {
  filter: brightness(85%);
}

@media (width >= 762px) {
  .footer__phone {
    font-size: 2.2rem;
  }
}

@media (width >= 1024px) {
  .footer__phone {
    font-size: 2.2rem;
  }
}

.footer__phone:before {
  content: url("phone-solid.86929fa8.svg");
  width: 20px;
  margin-right: 10px;
  display: inline-block;
}

@media (width >= 1024px) {
  .footer__phone:before {
    width: 25px;
  }
}

.footer__mail {
  color: #f9f9f9;
  font-size: 1.3rem;
  text-decoration: none;
}

@media (width >= 1024px) {
  .footer__mail {
    font-size: 16px;
  }
}

.footer__mail:hover {
  filter: brightness(85%);
}

@media (width >= 762px) {
  .footer__mail {
    font-size: 2.2rem;
  }
}

@media (width >= 1024px) {
  .footer__mail {
    font-size: 2.2rem;
  }
}

.footer__mail:before {
  content: url("envelope-solid.a8619a3c.svg");
  width: 20px;
  margin-right: 10px;
  display: inline-block;
}

@media (width >= 1024px) {
  .footer__mail:before {
    width: 25px;
  }
}

.footer__web {
  color: #f9f9f9;
  font-size: 1.3rem;
  text-decoration: none;
}

@media (width >= 1024px) {
  .footer__web {
    font-size: 16px;
  }
}

.footer__web:hover {
  filter: brightness(85%);
}

@media (width >= 762px) {
  .footer__web {
    font-size: 2.2rem;
  }
}

@media (width >= 1024px) {
  .footer__web {
    font-size: 2.2rem;
  }
}

.footer__web:before {
  content: url("globe-solid.177e806a.svg");
  width: 20px;
  margin-right: 10px;
  display: inline-block;
}

@media (width >= 1024px) {
  .footer__web:before {
    width: 25px;
  }
}

.footer label.error {
  display: none !important;
}

.footer form input.error {
  border-color: red;
}

:root {
  --f-spinner-width: 36px;
  --f-spinner-height: 36px;
  --f-spinner-color-1: #0000001a;
  --f-spinner-color-2: #11181ccc;
  --f-spinner-stroke: 2.75;
}

.f-spinner {
  width: var(--f-spinner-width);
  height: var(--f-spinner-height);
  margin: auto;
  padding: 0;
}

.f-spinner svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
  animation: 2s linear infinite f-spinner-rotate;
}

.f-spinner svg * {
  stroke-width: var(--f-spinner-stroke);
  fill: none;
}

.f-spinner svg :first-child {
  stroke: var(--f-spinner-color-1);
}

.f-spinner svg :last-child {
  stroke: var(--f-spinner-color-2);
  animation: 2s ease-in-out infinite f-spinner-dash;
}

@keyframes f-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes f-spinner-dash {
  0% {
    stroke-dasharray: 1 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 90 150;
    stroke-dashoffset: -124px;
  }
}

.f-throwOutUp {
  animation: var(--f-throw-out-duration, .175s) ease-out both f-throwOutUp;
}

.f-throwOutDown {
  animation: var(--f-throw-out-duration, .175s) ease-out both f-throwOutDown;
}

@keyframes f-throwOutUp {
  to {
    transform: translate3d(0, calc(var(--f-throw-out-distance, 150px) * -1), 0);
    opacity: 0;
  }
}

@keyframes f-throwOutDown {
  to {
    transform: translate3d(0, var(--f-throw-out-distance, 150px), 0);
    opacity: 0;
  }
}

.f-zoomInUp {
  animation: var(--f-transition-duration, .2s) ease .1s both f-zoomInUp;
}

.f-zoomOutDown {
  animation: var(--f-transition-duration, .2s) ease both f-zoomOutDown;
}

@keyframes f-zoomInUp {
  from {
    opacity: 0;
    transform: scale(.975)translate3d(0, 16px, 0);
  }

  to {
    opacity: 1;
    transform: scale(1)translate3d(0, 0, 0);
  }
}

@keyframes f-zoomOutDown {
  to {
    opacity: 0;
    transform: scale(.975)translate3d(0, 16px, 0);
  }
}

.f-fadeIn {
  animation: var(--f-transition-duration, .2s) var(--f-transition-easing, ease) var(--f-transition-delay, 0s) both f-fadeIn;
  z-index: 2;
}

.f-fadeOut {
  animation: var(--f-transition-duration, .2s) var(--f-transition-easing, ease) var(--f-transition-delay, 0s) both f-fadeOut;
  z-index: 1;
}

@keyframes f-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeOut {
  100% {
    opacity: 0;
  }
}

.f-fadeFastIn {
  animation: var(--f-transition-duration, .2s) ease-out both f-fadeFastIn;
  z-index: 2;
}

.f-fadeFastOut {
  animation: var(--f-transition-duration, .1s) ease-out both f-fadeFastOut;
  z-index: 2;
}

@keyframes f-fadeFastIn {
  0% {
    opacity: .75;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeFastOut {
  100% {
    opacity: 0;
  }
}

.f-fadeSlowIn {
  animation: var(--f-transition-duration, .5s) ease both f-fadeSlowIn;
  z-index: 2;
}

.f-fadeSlowOut {
  animation: var(--f-transition-duration, .5s) ease both f-fadeSlowOut;
  z-index: 1;
}

@keyframes f-fadeSlowIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-fadeSlowOut {
  100% {
    opacity: 0;
  }
}

.f-crossfadeIn {
  animation: var(--f-transition-duration, .2s) ease-out both f-crossfadeIn;
  z-index: 2;
}

.f-crossfadeOut {
  animation: calc(var(--f-transition-duration, .2s) * .5) linear .1s both f-crossfadeOut;
  z-index: 1;
}

@keyframes f-crossfadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes f-crossfadeOut {
  100% {
    opacity: 0;
  }
}

.f-slideIn.from-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideInNext;
}

.f-slideIn.from-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideInPrev;
}

.f-slideOut.to-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideOutNext;
}

.f-slideOut.to-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-slideOutPrev;
}

@keyframes f-slideInPrev {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-slideInNext {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-slideOutNext {
  100% {
    transform: translateX(-100%);
  }
}

@keyframes f-slideOutPrev {
  100% {
    transform: translateX(100%);
  }
}

.f-classicIn.from-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicInNext;
  z-index: 2;
}

.f-classicIn.from-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicInPrev;
  z-index: 2;
}

.f-classicOut.to-next {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicOutNext;
  z-index: 1;
}

.f-classicOut.to-prev {
  animation: var(--f-transition-duration, .85s) cubic-bezier(.16, 1, .3, 1) f-classicOutPrev;
  z-index: 1;
}

@keyframes f-classicInNext {
  0% {
    opacity: 0;
    transform: translateX(-75px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-classicInPrev {
  0% {
    opacity: 0;
    transform: translateX(75px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes f-classicOutNext {
  100% {
    opacity: 0;
    transform: translateX(-75px);
  }
}

@keyframes f-classicOutPrev {
  100% {
    opacity: 0;
    transform: translateX(75px);
  }
}

:root {
  --f-button-width: 40px;
  --f-button-height: 40px;
  --f-button-border: 0;
  --f-button-border-radius: 0;
  --f-button-color: #374151;
  --f-button-bg: #f8f8f8;
  --f-button-hover-bg: #e0e0e0;
  --f-button-active-bg: #d0d0d0;
  --f-button-shadow: none;
  --f-button-transition: all .15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 20px;
  --f-button-svg-height: 20px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: none;
  --f-button-svg-disabled-opacity: .65;
}

.f-button {
  box-sizing: content-box;
  width: var(--f-button-width);
  height: var(--f-button-height);
  border: var(--f-button-border);
  border-radius: var(--f-button-border-radius);
  color: var(--f-button-color);
  background: var(--f-button-bg);
  box-shadow: var(--f-button-shadow);
  pointer-events: all;
  cursor: pointer;
  transition: var(--f-button-transition);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

@media (hover: hover) {
  .f-button:hover:not([disabled]) {
    color: var(--f-button-hover-color);
    background-color: var(--f-button-hover-bg);
  }
}

.f-button:active:not([disabled]) {
  background-color: var(--f-button-active-bg);
}

.f-button:focus:not(:focus-visible) {
  outline: none;
}

.f-button:focus-visible {
  box-shadow: inset 0 0 0 var(--f-button-outline, 2px) var(--f-button-outline-color, var(--f-button-color));
  outline: none;
}

.f-button svg {
  width: var(--f-button-svg-width);
  height: var(--f-button-svg-height);
  fill: var(--f-button-svg-fill);
  stroke: currentColor;
  stroke-width: var(--f-button-svg-stroke-width);
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: var(--f-button-transform);
  filter: var(--f-button-svg-filter);
  pointer-events: none;
  transition: opacity .15s;
}

.f-button[disabled] {
  cursor: default;
}

.f-button[disabled] svg {
  opacity: var(--f-button-svg-disabled-opacity);
}

.f-carousel__nav .f-button.is-prev, .f-carousel__nav .f-button.is-next, .fancybox__nav .f-button.is-prev, .fancybox__nav .f-button.is-next {
  z-index: 1;
  position: absolute;
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-next {
  top: 50%;
  transform: translateY(-50%);
}

.is-horizontal .f-carousel__nav .f-button.is-prev, .is-horizontal .fancybox__nav .f-button.is-prev {
  left: var(--f-button-prev-pos);
}

.is-horizontal .f-carousel__nav .f-button.is-next, .is-horizontal .fancybox__nav .f-button.is-next {
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-prev, .is-horizontal.is-rtl .fancybox__nav .f-button.is-prev {
  left: auto;
  right: var(--f-button-next-pos);
}

.is-horizontal.is-rtl .f-carousel__nav .f-button.is-next, .is-horizontal.is-rtl .fancybox__nav .f-button.is-next {
  right: auto;
  left: var(--f-button-prev-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-next {
  top: auto;
  left: 50%;
  transform: translateX(-50%);
}

.is-vertical .f-carousel__nav .f-button.is-prev, .is-vertical .fancybox__nav .f-button.is-prev {
  top: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-next, .is-vertical .fancybox__nav .f-button.is-next {
  bottom: var(--f-button-next-pos);
}

.is-vertical .f-carousel__nav .f-button.is-prev svg, .is-vertical .f-carousel__nav .f-button.is-next svg, .is-vertical .fancybox__nav .f-button.is-prev svg, .is-vertical .fancybox__nav .f-button.is-next svg {
  transform: rotate(90deg);
}

.f-carousel__nav .f-button:disabled, .fancybox__nav .f-button:disabled {
  pointer-events: none;
}

html.with-fancybox {
  scroll-behavior: auto;
  width: auto;
  overflow: visible;
}

html.with-fancybox body {
  touch-action: none;
}

html.with-fancybox body.hide-scrollbar {
  margin-right: calc(var(--fancybox-body-margin, 0px)  + var(--fancybox-scrollbar-compensate, 0px));
  overscroll-behavior-y: none;
  width: auto;
  overflow: hidden !important;
}

.fancybox__container {
  --fancybox-color: #dbdbdb;
  --fancybox-hover-color: #fff;
  --fancybox-bg: #18181bfa;
  --fancybox-slide-gap: 10px;
  --f-spinner-width: 50px;
  --f-spinner-height: 50px;
  --f-spinner-color-1: #ffffff1a;
  --f-spinner-color-2: #bbb;
  --f-spinner-stroke: 3.65;
  direction: ltr;
  box-sizing: border-box;
  color: #f8f8f8;
  -webkit-tap-highlight-color: #0000;
  z-index: var(--fancybox-zIndex, 1050);
  transform-origin: 0 0;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  overscroll-behavior-y: contain;
  outline: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: visible;
}

.fancybox__container *, .fancybox__container :before, .fancybox__container :after {
  box-sizing: inherit;
}

.fancybox__container::backdrop {
  background-color: #0000;
}

.fancybox__backdrop {
  z-index: -1;
  background: var(--fancybox-bg);
  opacity: var(--fancybox-opacity, 1);
  will-change: opacity;
  position: fixed;
  inset: 0;
}

.fancybox__carousel {
  box-sizing: border-box;
  z-index: 10;
  flex: 1;
  min-height: 0;
  position: relative;
  overflow-x: clip;
  overflow-y: visible;
}

.fancybox__viewport {
  width: 100%;
  height: 100%;
}

.fancybox__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.fancybox__viewport.is-dragging {
  cursor: move;
  cursor: grabbing;
}

.fancybox__track {
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.fancybox__slide {
  margin: 0 var(--fancybox-slide-gap) 0 0;
  overscroll-behavior: contain;
  backface-visibility: hidden;
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 4px;
  display: flex;
  position: relative;
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

.fancybox__container:not(.is-compact) .fancybox__slide.has-close-btn {
  padding-top: 40px;
}

.fancybox__slide.has-iframe, .fancybox__slide.has-video, .fancybox__slide.has-html5video, .fancybox__slide.has-image {
  overflow: hidden;
}

.fancybox__slide.has-image.is-animating, .fancybox__slide.has-image.is-selected {
  overflow: visible;
}

.fancybox__slide:before, .fancybox__slide:after {
  content: "";
  flex: 0 0 0;
  margin: auto;
}

.fancybox__backdrop:empty, .fancybox__viewport:empty, .fancybox__track:empty, .fancybox__slide:empty {
  display: block;
}

.fancybox__content {
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  cursor: default;
  z-index: 20;
  border-radius: 0;
  flex-direction: column;
  align-self: center;
  max-width: 100%;
  margin: 0;
  padding: 2rem;
  display: flex;
  position: relative;
}

.is-loading .fancybox__content {
  opacity: 0;
}

.is-draggable .fancybox__content {
  cursor: move;
  cursor: grab;
}

.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}

.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}

.is-dragging .fancybox__content {
  cursor: move;
  cursor: grabbing;
}

.fancybox__content [data-selectable], .fancybox__content [contenteditable] {
  cursor: auto;
}

.fancybox__slide.has-image > .fancybox__content {
  backface-visibility: hidden;
  background: center / contain no-repeat;
  min-height: 1px;
  padding: 0;
  transition: none;
  transform: translate3d(0, 0, 0);
}

.fancybox__slide.has-image > .fancybox__content > picture > img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.is-animating .fancybox__content, .is-dragging .fancybox__content {
  will-change: transform, width, height;
}

.fancybox-image {
  object-fit: contain;
  -webkit-user-select: none;
  user-select: none;
  filter: blur();
  width: 100%;
  height: 100%;
  min-height: 0;
  margin: auto;
  display: block;
}

.fancybox__caption {
  overflow-wrap: anywhere;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  cursor: auto;
  visibility: visible;
  flex-shrink: 0;
  align-self: center;
  max-width: 100%;
  margin: 0;
  padding: 14px 0 4px;
  line-height: 1.375;
}

.is-loading .fancybox__caption, .is-closing .fancybox__caption {
  opacity: 0;
  visibility: hidden;
}

.is-compact .fancybox__caption {
  padding-bottom: 0;
}

.f-button.is-close-btn {
  --f-button-svg-stroke-width: 2;
  z-index: 40;
  position: absolute;
  top: 0;
  right: 8px;
}

.fancybox__content > .f-button.is-close-btn {
  --f-button-width: 34px;
  --f-button-height: 34px;
  --f-button-border-radius: 4px;
  --f-button-color: var(--fancybox-color, #fff);
  --f-button-hover-color: var(--fancybox-color, #fff);
  --f-button-bg: transparent;
  --f-button-hover-bg: transparent;
  --f-button-active-bg: transparent;
  --f-button-svg-width: 22px;
  --f-button-svg-height: 22px;
  opacity: .75;
  position: absolute;
  top: -38px;
  right: 0;
}

.is-loading .fancybox__content > .f-button.is-close-btn, .is-zooming-out .fancybox__content > .f-button.is-close-btn {
  visibility: hidden;
}

.fancybox__content > .f-button.is-close-btn:hover {
  opacity: 1;
}

.fancybox__footer {
  margin: 0;
  padding: 0;
  position: relative;
}

.fancybox__footer .fancybox__caption {
  opacity: var(--fancybox-opacity, 1);
  width: 100%;
  padding: 24px;
  transition: all .25s;
}

.is-compact .fancybox__footer {
  z-index: 20;
  background: #18181b80;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.is-compact .fancybox__footer .fancybox__caption {
  padding: 12px;
}

.is-compact .fancybox__content > .f-button.is-close-btn {
  --f-button-border-radius: 50%;
  --f-button-color: #fff;
  --f-button-hover-color: #fff;
  --f-button-outline-color: #000;
  --f-button-bg: #0009;
  --f-button-active-bg: #0009;
  --f-button-hover-bg: #0009;
  --f-button-svg-width: 18px;
  --f-button-svg-height: 18px;
  --f-button-svg-filter: none;
  top: 5px;
  right: 5px;
}

.fancybox__nav {
  --f-button-width: 50px;
  --f-button-height: 50px;
  --f-button-border: 0;
  --f-button-border-radius: 50%;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: transparent;
  --f-button-hover-bg: #18181b4d;
  --f-button-active-bg: #18181b80;
  --f-button-shadow: none;
  --f-button-transition: all .15s ease;
  --f-button-transform: none;
  --f-button-svg-width: 26px;
  --f-button-svg-height: 26px;
  --f-button-svg-stroke-width: 2.5;
  --f-button-svg-fill: none;
  --f-button-svg-filter: drop-shadow(1px 1px 1px #18181b80);
  --f-button-svg-disabled-opacity: .65;
  --f-button-next-pos: 1rem;
  --f-button-prev-pos: 1rem;
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__nav .f-button:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: -30px -20px;
}

.is-idle .fancybox__nav {
  animation: .15s ease-out both f-fadeOut;
}

.is-idle.is-compact .fancybox__footer {
  pointer-events: none;
  animation: .15s ease-out both f-fadeOut;
}

.fancybox__slide > .f-spinner {
  margin: var(--f-spinner-top, calc(var(--f-spinner-width) * -.5)) 0 0 var(--f-spinner-left, calc(var(--f-spinner-height) * -.5));
  z-index: 30;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
}

.fancybox-protected {
  z-index: 40;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  inset: 0;
}

.fancybox-ghost {
  object-fit: contain;
  z-index: 40;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.fancybox-focus-guard {
  opacity: 0;
  pointer-events: none;
  outline: none;
  position: fixed;
}

.fancybox__container:not([aria-hidden]) {
  opacity: 0;
}

.fancybox__container.is-animated[aria-hidden="false"] > :not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden="false"] .fancybox__carousel > :not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden="false"] .fancybox__slide > :not(.fancybox__content) {
  animation: var(--f-interface-enter-duration, .25s) ease .1s backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden="false"] .fancybox__backdrop {
  animation: var(--f-backdrop-enter-duration, .35s) ease backwards f-fadeIn;
}

.fancybox__container.is-animated[aria-hidden="true"] > :not(.fancybox__backdrop, .fancybox__carousel), .fancybox__container.is-animated[aria-hidden="true"] .fancybox__carousel > :not(.fancybox__viewport), .fancybox__container.is-animated[aria-hidden="true"] .fancybox__slide > :not(.fancybox__content) {
  animation: var(--f-interface-exit-duration, .15s) ease forwards f-fadeOut;
}

.fancybox__container.is-animated[aria-hidden="true"] .fancybox__backdrop {
  animation: var(--f-backdrop-exit-duration, .35s) ease forwards f-fadeOut;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  flex-shrink: 1;
  max-width: 100%;
  min-height: 1px;
  overflow: visible;
}

.has-iframe .fancybox__content, .has-map .fancybox__content, .has-pdf .fancybox__content {
  width: calc(100% - 120px);
  height: 90%;
}

.fancybox__container.is-compact .has-iframe .fancybox__content, .fancybox__container.is-compact .has-map .fancybox__content, .fancybox__container.is-compact .has-pdf .fancybox__content {
  width: 100%;
  height: 100%;
}

.has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  width: 960px;
  max-width: 100%;
  height: 540px;
  max-height: 100%;
}

.has-map .fancybox__content, .has-pdf .fancybox__content, .has-youtube .fancybox__content, .has-vimeo .fancybox__content, .has-html5video .fancybox__content {
  color: #fff;
  background: #18181be6;
  padding: 0;
}

.has-map .fancybox__content {
  background: #e5e3df;
}

.fancybox__html5video, .fancybox__iframe {
  background: none;
  border: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.fancybox-placeholder {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.f-carousel__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-outline: 0;
  --f-thumb-outline-color: #5eb0ef;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
  --f-thumb-border-radius: 2px;
  --f-thumb-offset: 0px;
  --f-button-next-pos: 0;
  --f-button-prev-pos: 0;
}

.f-carousel__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: .5;
  --f-thumb-hover-opacity: 1;
  --f-thumb-selected-opacity: 1;
}

.f-carousel__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 16px;
  --f-thumb-clip-width: 46px;
}

.f-thumbs {
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  perspective: 1000px;
  flex: none;
  margin: 0;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.f-thumbs .f-spinner {
  z-index: -1;
  background-image: linear-gradient(#ebeff2, #e2e8f0);
  border-radius: 2px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.f-thumbs .f-spinner svg {
  display: none;
}

.f-thumbs.is-vertical {
  height: 100%;
}

.f-thumbs__viewport {
  width: 100%;
  height: auto;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.f-thumbs__track {
  display: flex;
}

.f-thumbs__slide {
  box-sizing: content-box;
  width: var(--f-thumb-width);
  height: var(--f-thumb-height);
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.f-thumbs__slide.is-loading img {
  opacity: 0;
}

.is-classic .f-thumbs__viewport {
  height: 100%;
}

.is-modern .f-thumbs__track {
  width: max-content;
}

.is-modern .f-thumbs__track:before {
  content: "";
  top: 0;
  bottom: 0;
  left: calc((var(--f-thumb-clip-width, 0)) * -.5);
  width: calc(var(--width, 0) * 1px + var(--f-thumb-clip-width, 0));
  cursor: pointer;
  position: absolute;
}

.is-modern .f-thumbs__slide {
  width: var(--f-thumb-clip-width);
  transform: translate3d(calc(var(--shift, 0) * -1px), 0, 0);
  pointer-events: none;
  transition: none;
}

.is-modern.is-resting .f-thumbs__slide {
  transition: transform .33s;
}

.is-modern.is-resting .f-thumbs__slide__button {
  transition: clip-path .33s;
}

.is-using-tab .is-modern .f-thumbs__slide:focus-within {
  filter: drop-shadow(-1px 0px 0px var(--f-thumb-outline-color)) drop-shadow(2px 0px 0px var(--f-thumb-outline-color)) drop-shadow(0px -1px 0px var(--f-thumb-outline-color)) drop-shadow(0px 2px 0px var(--f-thumb-outline-color));
}

.f-thumbs__slide__button {
  appearance: none;
  width: var(--f-thumb-width);
  border-radius: var(--f-thumb-border-radius);
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  opacity: var(--f-thumb-opacity);
  background: none;
  border: 0;
  outline: none;
  height: 100%;
  margin: 0 -100%;
  padding: 0;
  transition: opacity .2s;
  position: relative;
  overflow: hidden;
}

.f-thumbs__slide__button:hover {
  opacity: var(--f-thumb-hover-opacity);
}

.f-thumbs__slide__button:focus:not(:focus-visible) {
  outline: none;
}

.f-thumbs__slide__button:focus-visible {
  opacity: var(--f-thumb-selected-opacity);
  outline: none;
}

.is-modern .f-thumbs__slide__button {
  --clip-path: inset(0 calc(((var(--f-thumb-width, 0)  - var(--f-thumb-clip-width, 0))) * (1 - var(--progress, 0)) * .5) round var(--f-thumb-border-radius, 0));
  clip-path: var(--clip-path);
}

.is-classic .is-nav-selected .f-thumbs__slide__button {
  opacity: var(--f-thumb-selected-opacity);
}

.is-classic .is-nav-selected .f-thumbs__slide__button:after {
  content: "";
  border: var(--f-thumb-outline, 0) solid var(--f-thumb-outline-color, transparent);
  border-radius: var(--f-thumb-border-radius);
  z-index: 10;
  height: auto;
  animation: .2s ease-out f-fadeIn;
  position: absolute;
  inset: 0;
}

.f-thumbs__slide__img {
  padding: var(--f-thumb-offset);
  box-sizing: border-box;
  pointer-events: none;
  object-fit: cover;
  border-radius: var(--f-thumb-border-radius);
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.f-thumbs.is-horizontal .f-thumbs__track {
  padding: 8px 0 12px;
}

.f-thumbs.is-horizontal .f-thumbs__slide {
  margin: 0 var(--f-thumb-gap) 0 0;
}

.f-thumbs.is-vertical .f-thumbs__track {
  flex-wrap: wrap;
  padding: 0 8px;
}

.f-thumbs.is-vertical .f-thumbs__slide {
  margin: 0 0 var(--f-thumb-gap) 0;
}

.fancybox__thumbs {
  --f-thumb-width: 96px;
  --f-thumb-height: 72px;
  --f-thumb-border-radius: 2px;
  --f-thumb-outline: 2px;
  --f-thumb-outline-color: #ededed;
  opacity: var(--fancybox-opacity, 1);
  transition: max-height .35s cubic-bezier(.23, 1, .32, 1);
  position: relative;
}

.fancybox__thumbs.is-classic {
  --f-thumb-gap: 8px;
  --f-thumb-opacity: .5;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-classic .f-spinner {
  background-image: linear-gradient(#ffffff1a, #ffffff0d);
}

.fancybox__thumbs.is-modern {
  --f-thumb-gap: 4px;
  --f-thumb-extra-gap: 16px;
  --f-thumb-clip-width: 46px;
  --f-thumb-opacity: 1;
  --f-thumb-hover-opacity: 1;
}

.fancybox__thumbs.is-modern .f-spinner {
  background-image: linear-gradient(#ffffff1a, #ffffff0d);
}

.fancybox__thumbs.is-horizontal {
  padding: 0 var(--f-thumb-gap);
}

.fancybox__thumbs.is-vertical {
  padding: var(--f-thumb-gap) 0;
}

.is-compact .fancybox__thumbs {
  --f-thumb-width: 64px;
  --f-thumb-clip-width: 32px;
  --f-thumb-height: 48px;
  --f-thumb-extra-gap: 10px;
}

.fancybox__thumbs.is-masked {
  max-height: 0 !important;
}

.is-closing .fancybox__thumbs {
  transition: none !important;
}

.fancybox__toolbar {
  --f-progress-color: var(--fancybox-color, #fffffff0);
  --f-button-width: 46px;
  --f-button-height: 46px;
  --f-button-color: var(--fancybox-color);
  --f-button-hover-color: var(--fancybox-hover-color);
  --f-button-bg: #18181ba6;
  --f-button-hover-bg: #464649a6;
  --f-button-active-bg: #5a5a5da6;
  --f-button-border-radius: 0;
  --f-button-svg-width: 24px;
  --f-button-svg-height: 24px;
  --f-button-svg-stroke-width: 1.5;
  --f-button-svg-filter: drop-shadow(1px 1px 1px #18181b26);
  --f-button-svg-fill: none;
  --f-button-svg-disabled-opacity: .65;
  color: var(--fancybox-color, currentColor);
  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px #00000080);
  pointer-events: none;
  z-index: 20;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI Adjusted, Segoe UI, Liberation Sans, sans-serif;
  display: flex;
}

.fancybox__toolbar :focus-visible {
  z-index: 1;
}

.fancybox__toolbar.is-absolute, .is-compact .fancybox__toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.is-idle .fancybox__toolbar {
  pointer-events: none;
  animation: .15s ease-out both f-fadeOut;
}

.fancybox__toolbar__column {
  flex-flow: wrap;
  align-content: flex-start;
  display: flex;
}

.fancybox__toolbar__column.is-left, .fancybox__toolbar__column.is-right {
  flex-grow: 1;
  flex-basis: 0;
}

.fancybox__toolbar__column.is-right {
  flex-wrap: nowrap;
  justify-content: flex-end;
  display: flex;
}

.fancybox__infobar {
  line-height: var(--f-button-height);
  text-align: center;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 5px;
  font-size: 17px;
}

.fancybox__infobar span {
  padding: 0 5px;
}

.fancybox__infobar:not(:first-child):not(:last-child) {
  background: var(--f-button-bg);
}

[data-fancybox-toggle-slideshow] {
  position: relative;
}

[data-fancybox-toggle-slideshow] .f-progress {
  opacity: .3;
  height: 100%;
}

[data-fancybox-toggle-slideshow] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-slideshow] svg g:last-child, .has-slideshow [data-fancybox-toggle-slideshow] svg g:first-child {
  display: none;
}

.has-slideshow [data-fancybox-toggle-slideshow] svg g:last-child, [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: flex;
}

[data-fancybox-toggle-fullscreen] svg g:last-child, :fullscreen [data-fancybox-toggle-fullscreen] svg g:first-child {
  display: none;
}

:fullscreen [data-fancybox-toggle-fullscreen] svg g:last-child {
  display: flex;
}

.f-progress {
  transform-origin: 0;
  background: var(--f-progress-color, var(--f-carousel-theme-color, #0091ff));
  z-index: 30;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  height: 3px;
  transition-property: transform;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
}

/*# sourceMappingURL=index.c6b6a08a.css.map */
